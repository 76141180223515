<template>
  <Header />
  <div class="container">
    <About />
    <Contact />
    <Details />
    <Carousel />
  </div>
  <footer>
  </footer>
</template>

<script>
import Header from "./components/Header.vue";
import About from "./components/About.vue";
import Contact from "./components/Contact.vue";
import Details from "./components/Details.vue";
import Carousel from "./components/Carousel.vue";

export default {
  name: "App",
  components: {
    Header,
    About,
    Contact,
    Details,
    Carousel
  },
};
</script>

<style>
body {
  background-color: black;
  color: white;
  font-family: 'PT Sans', sans-serif;
  background-image:linear-gradient(0deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("https://i.imgur.com/bUAMDLx.png");
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

section{
  margin-top: 4em;
}

.header-icon {
  float: left;
}

h1{
  color: #94794e;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 1.1;
}

.header-icon h3 {
  color: white;
  text-align: left;
  font-weight: bold;
}

.menu h4 {
  color: white;
  text-align: right;
}

.line{
    border-bottom: white;
    border-width: 1px;
    border-style: solid;
    width: 50%;
    margin: 2em auto;
}

p{
  font-family: 'PT Sans', sans-serif;
  font-size: 1.25rem;
  line-height: 1.9;
}

footer{
  margin-top: 10em;
}

::-webkit-scrollbar
{
	width: 8px;
	background-color: #94794e;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: black;
}
</style>
