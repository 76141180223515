<template>
  <section id="contact">
    <div class="row align-items-center">
      <div class="col margin-correction">
        <h1>Contact</h1>
        <div class="line"></div>
        <!-- <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim
          impedit, nam rem ex delectus sequi voluptatum explicabo cumque eaque
          magnam est dolor voluptates omnis autem nobis! Ipsa quasi quae ab.
        </p> -->
      </div>
      <div class="col-lg-6">
        <div class="card text-white bg-dark mb-3">
            <div class="card-body">
                <p>
                    <span>Phone Number:</span>
                    647-244-4040
                </p>
                <p>
                    <span>Email:</span>
                    kingandientertainment@yahoo.com
                </p>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-dark{
    background-color: black !important;
}

.card-body p{
    text-align: left;
    margin-left: 10%;
}

.card-body p span{
    color: #94794e;
;
}

</style>