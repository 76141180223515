<template>
  <section id="details">
    <!-- class="row">
      <div class="col align-self-center">
        <h1>Details</h1>
      </div>
    </!-->
    <div class="row">
      <div class="col">
        <p>
          The most dynamic duo. We both are the life of the party and we bring that positive vibe and energy to every party/social event we perform at.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>