<template>
  <section id="pictures">
    <div class="row justify-content-center" >
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div v-for="(picture, index) in pictures" :key="index" :class="['carousel-item', {'active': index === 0}]">
            <img class="d-block w-100" :src="'images/' + picture" :alt="'picture ' + (index + 1) + '/' + pictures.length">
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    pictures: [
      "IMG_4193.JPG",
      "IMG_4197.JPG",
      "IMG_4201.JPG",
      "IMG_4205.JPG",
      "IMG_4209.JPG",
      "IMG_4215.JPG",
      "IMG_4220.JPG",
      "IMG_4224.JPG",
      "IMG_4229.JPG",
      "IMG_4233.JPG",
      "IMG_4237.JPG",
      "IMG_4243.JPG",
      "IMG_5458.JPG",
      "IMG_4194.JPG",
      "IMG_4198.JPG",
      "IMG_4202.JPG",
      "IMG_4206.JPG",
      "IMG_4210.JPG",
      "IMG_4216.JPG",
      "IMG_4221.JPG",
      "IMG_4225.JPG",
      "IMG_4230.JPG",
      "IMG_4234.JPG",
      "IMG_4238.JPG",
      "IMG_4245.JPG",
      "IMG_5459.JPG",
      "IMG_4195.JPG",
      "IMG_4199.JPG",
      "IMG_4203.JPG",
      "IMG_4207.JPG",
      "IMG_4211.JPG",
      "IMG_4217.JPG",
      "IMG_4222.JPG",
      "IMG_4227.JPG",
      "IMG_4231.JPG",
      "IMG_4235.JPG",
      "IMG_4239.JPG",
      "IMG_4247.JPG",
      "IMG_5461.JPG",
      "IMG_4196.JPG",
      "IMG_4200.JPG",
      "IMG_4204.JPG",
      "IMG_4208.JPG",
      "IMG_4214.JPG",
      "IMG_4218.JPG",
      "IMG_4223.JPG",
      "IMG_4228.JPG",
      "IMG_4232.JPG",
      "IMG_4236.JPG",
      "IMG_4242.JPG",
      "IMG_4248.JPG",
      "IMG_5462.JPG",
    ]
  }),
};
</script>

<style scoped>
img{
  height: auto;
}
</style>
