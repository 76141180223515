<template>
  <section id="about">
    <div class="row align-items-center">
      <div class="col-lg-6 margin-correction">
        <img class="section-image" src="../assets/KingAndIMain.png" />
      </div>
      <div class="col">
        <h1>About</h1>
        <div class="line"></div>
        <p>
          ‘King & I’ entertainment - the ‘Best’ DJ and MC ‘Duo’ in town. We work
          with you to bring you a once in a lifetime experience and transform
          your event with sound and style, while keeping the crowd engaged. We
          will set you up with perfect sound, regardless of whether your venue
          is a warm cozy room, large banquet hall or conference centre and
          create customized mixes to meet your music needs. Allow us to do the
          organizing and worrying while you let your hair down. Book us now and
          let us bring the life to your party !!
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
img {
  width: 20em;
}

.margin-correction {
  margin-bottom: 5em;
}
</style>