<template>
  <header>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col header-icon">
          <!-- <h3>King & I</h3> -->
          <img src="../assets/KingAndILogo.svg" alt="King and I Logo">
        </div>
        <div class="col d-none d-lg-block menu">
          <div class="row">
            <div class="col"><a href="#about"><h4>About</h4></a></div>
            <div class="col"><a href="#contact"><h4>Contact</h4></a></div>
            <div class="col"><a href="#details"><h4>Details</h4></a></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style scoped>
img{
  width: 20em;
  height: 5em;
  margin-left: -40%;
}
</style>